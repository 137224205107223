.foot{         
       display: flex;
       flex-direction: column;
            justify-content: space-between;
            align-items:center;
           width: 100%;
            z-index: 10;   
            transition:  0.5s linear;
          background-color: #000;
            background-size: 100% 100%;
            background-repeat: no-repeat;   
            padding: 2rem 0; 
           
}
@media only screen and (max-width: 768px) {
    .foot{         
        display: flex;
             justify-content: space-between;
             align-items:center;
             flex-direction: column;
            
              width: 100%;
             z-index: 10;   
             transition:  0.5s linear;
             background-image: none;
             background-repeat: no-repeat;   
             padding-top: 2rem;    
             background-color: #000;
 }
}