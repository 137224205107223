 /* Navigation Bar */



         .nav{
            display: flex;
            justify-content: space-between;
            align-items:center;
            height: 6rem; width: 100%;
            position: fixed;
            top: 0;
            z-index: 10;   
            transition:  0.5s linear;
           
        }
        .navActiveLight{
            display: flex;
            justify-content: space-between;
            align-items:center;
            height: 5.8rem; width: 100%;
            position: fixed;
            top: 0;
            z-index: 10;
            transition:  0.5s linear;
           background-color: rgba(0,0,0,0.7);
        }
        .navActiveDark{
          display: flex;
          justify-content: space-between;
          align-items:center;
          height: 5rem; width: 100%;
          position: fixed;
          top: 0;
          /* font-family: 'Oswald', sans-serif !important ; */
          background: rgba(229, 229, 229, 0.85);
          /* opacity: 0.9; */
          z-index: 10;
          transition:  0.5s linear;
      }
        .Hactive{
          
          transition:  0.5s linear;
          font-size: 1.5rem;
        }
        .H{
      
          transition:  0.5s linear;
          font-size: 2rem;
        }
        .Logo{
          width: 6rem;
          transition:  0.5s linear;
          margin: 0.8rem 0 0 0;
      }

        .LogoActive{
          width: 6rem;
          transition:  0.5s linear;
          margin: 0.6rem 0 0 0;
      }

       
        .list{
            list-style: none;
            display: flex;
            justify-content: space-between;
             /* background: aqua;  */
            width: 40rem;
            text-decoration: none;
        }
 

          
          .list * {
            box-sizing: border-box;
            transition: all .35s ease;
         
            
          }
          
          .list li {
            display: inline-block;
            list-style: outside none none;
            margin: .5em 1em;
            padding: 0;
          }
          
         
          .list a {
            padding: .5em .8em;
            color: #FFF;
            position: relative;
            text-decoration: none;
            font-size: 18px;
            font-weight: 400;
          }
          
          .list a::before,
          .list a::after {
            content: '';
            height: 14px;
            width: 14px;
            position: absolute;
            transition: all .35s ease;
            opacity: 0;
          }
          
          /* .list a::before {
            content: '';
            right: 0;
            top: 0;
            border-top: 3px solid #C92CE2;
            border-right: 3px solid #C92CE2;
            transform: translate(-100%, 50%);
          }
          
          .list a:after {
            content: '';
            left: 0;
            bottom: 0;
            border-bottom: 3px solid #C92CE2;
            border-left: 3px solid #C92CE2;
            transform: translate(100%, -50%)
          } */
          
          .list a:hover:before,
          .list a:hover:after{
            transform: translate(0,0);
            opacity: 1;
          }
          
          .list a:hover {
            color: violet !important;
          }
          

        
        .container{
            display: none;
        }
        .hamburger{
            display: none;
        }
      
       
        @media(max-width: 1250px){
            .nav{
                justify-content: space-between;
                /* padding-left: 2rem; */
                height: 5rem;
                position: fixed;
                align-items: center;
                flex-direction: row;
                width: 100%;
            }
            .navActiveLight{
                justify-content: space-between;
                /* padding-left: 2rem; */
                height: 4.8rem;
                position: fixed;
                z-index: 10;
                opacity: 1;
                flex-direction: row;
            }
            .navActiveDark{
              justify-content: space-between;
              /* padding-left: 2rem; */
              height: 4rem;
              position: fixed;
              background: rgba(229, 229, 229, 0.85);
              z-index: 10;
              opacity: 1;
              flex-direction: row;
          }
            .none{
                display: none;
            }
            
            .list{
               display: none;
            }

            .Logo{
              width: 4rem;
              transition:  0.5s linear;
              margin: 0 0 0;
          }

          .LogoActive{
            width: 4rem;
            transition:  0.5s linear;
            margin:  0 0 0;
        }
            
          .LogoB{
            width: 8rem;
            transition:  0.5s linear;
        }
        }
                               /* Navigartion Bar Ends */